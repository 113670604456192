import React, { useState, useEffect, createContext, FunctionComponent } from "react"

const basicStorage = {
    key: "",
    showActionbar: true,
    actionbarActiveTab: "chat-assistant",
    cache: {}
}

type StorageContextType = {
    storage: { [key: string]: any },
    updateStorage: (key: string, value: any) => void,
    clearStorage: () => void,
}

const StorageContext = createContext<StorageContextType | undefined>(undefined)

const StorageContextProvider: FunctionComponent = ({ children }) => {
    const [storage, setStorage] = useState({})

    const setLocalStorage = (storageJson: {}) => {
        localStorage.setItem("config", JSON.stringify(storageJson))
    }

    const getLocalStorage = () => {
        return localStorage.getItem("config")
    }

    const updateStorage = (key: string, value: any) => {
        setStorage((oldValues) => {
            const newValues = { ...oldValues, [key]: value }
            setLocalStorage(newValues)
            return newValues
        })
    }

    const clearStorage = () => {
        setStorage(basicStorage)
        setLocalStorage(basicStorage)
    }

    useEffect(() => {
        const localStorageConfig = getLocalStorage()
        if (!localStorageConfig) {
            setStorage(basicStorage)
            setLocalStorage(basicStorage)
        } else {
            try {
                const localStorageConfigJson = JSON.parse(localStorageConfig)
                setStorage(localStorageConfigJson)
            } catch (error) {
                console.error("localStorage not valid JSON")
                setStorage(basicStorage)
            }
        }
    }, [])

    return (
        <StorageContext.Provider value={{ storage, updateStorage, clearStorage }}>
            {children}
        </StorageContext.Provider>
    )
}

export { StorageContext, StorageContextProvider }