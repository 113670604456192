import React from 'react'
import { Link } from 'react-router-dom'

const colorMapping: { [key: string]: any } = {
    green: "border-green-800",
    red: "border-red-800"
}

type ActivityCardType = {
    name: string,
    id: string,
    color: string,
    processId: string
}

const ActivityCard: React.FC<ActivityCardType> = ({ name, id, color, processId }) => {
    const bgColor = Object.keys(colorMapping).find((key) => key === color) ? colorMapping[color] : "border-blue-800"

    return (
        <div className={`p-2 rounded shadow m-2 text-center border-t-8 mb-auto ${bgColor}`}>
            <h2>{name}</h2>
            <Link to={`/view/process/${processId}/${id}`}>
                <button className="bg-gray-200 rounded-sm p-1 text-sm">
                    Open Activity
                </button>
            </Link>
        </div>
    )
}

export default ActivityCard
