import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'


type MenuTabType = {
    icon: IconDefinition,
    color: string,
    text?: string,
    active?: boolean,
    onClick?: () => void
}

const MenuTab: React.FC<MenuTabType> = ({ icon, color, text, active, onClick }) => {
    const [isHover, setIsHover] = useState(false)
    const colorList = color.split("-")
    const hoverColor = "bg-" + colorList[1] + "-100"
    return (
        <div
            className={`my-auto p-3 text-center border-r-2 border-gray-200 ${onClick && "cursor-pointer"} ${isHover ? hoverColor : "bg-gray-100"}`}
            onClick={onClick}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <FontAwesomeIcon icon={icon} size="lg" className={color} />
            {active ? <span className="ml-2">{text}</span> : ""}
        </div>
    )
}

export default MenuTab