import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Split from 'react-split'


import Card from 'components/Card'
import ActivityCard from 'Process/Process/components/ActivityCard'
import useFetch from 'services/useFetch'
import Section from 'components/Section'
import { toast } from "react-toastify"
import { NoMatchBody } from 'views/NoMatch'


const Panel = (props) => {
    const { children, classStyle } = props

    return (
        <div className={"h-full " + classStyle} >
            {children}
        </div>
    )
}

const ProcessBody = (props) => {

    const { processId } = props
    const [activityData, setActivityData] = useState([])
    const [processData, setProcessData] = useState({})
    const { status, data } = useFetch(`/process/${processId}`)

    useEffect(() => {
        if (status === 'error') {
            toast.error("Something went wrong retrieving that content. Please try again later")
            console.error(status.data)
        } else if (status === 'fetched') {
            const { activities, ...fetchedProcessData } = data.data
            setActivityData(activities)
            setProcessData(fetchedProcessData)
        }
    }, [processId, status, data])

    return (
        <>
            {status === "error" ? <NoMatchBody /> :
                <Split
                    sizes={[70, 30]}
                    minSize={100}
                    gutterSize={3}
                    direction="vertical"
                    cursor="n-resize"
                    className="flex flex-col h-full"
                >
                    <Panel classStyle="overflow-y-auto">
                        <h1 className="mt-0 text-xl font-semibold">{processData.name}</h1>
                        <div className="grid grid-cols-4 gap-4 mb-auto">
                            {activityData.map((activity) =>
                                <ActivityCard
                                    key={activity.id}
                                    id={activity.id}
                                    name={activity.name}
                                    color={activity.color}
                                    processId={processId}
                                />)
                            }
                        </div>
                    </Panel>
                    <Panel classStyle="overflow-y-auto ">
                        <div className="mx-4 md:mx-16 lg:mx-32 ">
                            {processData.tags &&
                                <Section
                                    sectionName="Tags"
                                    contents={processData.tags.map(
                                        (tag, i) => <span className="bg-gray-100 m-1 py-0.5 px-2 rounded shadow" key={i}>{tag}</span>
                                    )}
                                />
                            }
                            <Section
                                sectionName="Description"
                                contents={processData.description}
                            />
                        </div>
                    </Panel>
                </Split>
            }
        </>
    )
}

const Process = () => {
    let location = useLocation()
    let { processId } = useParams()
    return (
        <Card
            title="Process Activity"
            body={<ProcessBody processId={processId} />}
            key={location.pathname}
            customStyle="h-full"
        />
    )
}

export default Process