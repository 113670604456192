import React, { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faExternalLinkAlt, faUserTie, faServer, IconDefinition } from '@fortawesome/free-solid-svg-icons'

import { StorageContext } from "services/StorageContext"

type ProcessDetailType = {
    type: string,
    name: string
}

const ProcessDetail: React.FC<ProcessDetailType> = ({ type, name }) => {
    const { updateStorage } = useContext(StorageContext)!
    const [isHover, setIsHover] = useState(false)

    const typeMapper: { [key: string]: IconDefinition } = {
        "TMS Location": faMapMarkerAlt,
        Participant: faUserTie,
        System: faServer
    }

    return (
        <span
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className={`flex flex-row w-full py-1 px-1 ${isHover && "bg-gray-50 shadow-inner"}`}
        >
            <div className="inline-block">
                <FontAwesomeIcon icon={typeMapper[type]} className="mr-2" />
                {name}
            </div>
            <div className="flex-grow"></div>
            <div className="inline-block">
                <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    className={`cursor-pointer  ${isHover ? "text-black" : "text-gray-300"}`}
                    onClick={() => {
                        updateStorage("actionbarActiveTab", "linker")
                        updateStorage("showActionbar", true)
                    }}
                />
            </div>
        </span>
    )
}

export default ProcessDetail