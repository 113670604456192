import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { usePopper } from 'react-popper'

import ContextMenu from 'components/Card/components/ContextMenu'

type CardTypes = {
    body: JSX.Element | JSX.Element[],
    title: string,
    customStyle?: string,
    contextMenu?: JSX.Element[]
}

const Card: React.FC<CardTypes> = ({ body, title, customStyle, contextMenu }) => {
    const buttonRef = useRef(null);
    const popperRef = useRef(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
    const [isShow, setIsShow] = useState(false)

    const { styles, attributes } = usePopper(buttonRef.current, popperRef.current, {
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
        placement: "bottom-end",
    })

    return (
        <div className="w-full h-full flex flex-col">
            <div className="bg-gray-100 w-full h-14 border-b-2 border-gray-200 flex-shrink-0">
                <div className="flex flex-row">
                    <h2 className="text-2xl ml-2">{title}</h2>
                    <div className="flex-grow"></div>
                    <button
                        className="my-auto mr-2"
                        ref={buttonRef}
                        onClick={() => setIsShow((prevValue) => !prevValue)}
                    >
                        <FontAwesomeIcon
                            icon={faEllipsisH}
                            size="lg"
                        />
                    </button>
                    {(isShow && contextMenu) &&
                        <div ref={popperRef} style={styles.popper} {...attributes.popper} >
                            <div ref={setArrowElement} style={styles.arrow} />
                            <ContextMenu contextItems={contextMenu} />
                        </div>
                    }
                </div>
            </div>
            <div className={"overflow-y-auto bg-white px-4 py-5 " + customStyle}>
                {body}
            </div>
        </div>
    )
}

export default Card