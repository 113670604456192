import React from 'react'

type SectionType = {
    sectionName: string,
    contents: JSX.Element | any
}

const Section: React.FC<SectionType> = ({ sectionName, contents }) => {
    return (
        <>
            <h2 className="font-semibold text-lg mb-0 border-b border-gray-200">{sectionName}</h2>
            <div className="mt-2 mb-4">
                {contents}
            </div>
        </>
    )
}

export default Section