import React from "react"

import { Switch, Route } from "react-router-dom"

import SidePanel from "views/SidePanel"
import ActionBar from "views/ActionBar"
import Main from "Main"
import { LoginCallback, SecureRoute } from "@okta/okta-react"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css";

const App = () => {
    return (
        <>
            <Switch>
                <Route path="/login/callback" component={LoginCallback} />
                <Route path="/logged_out" component={() => {
                    window.location.href = 'https://covarius.com/';
                    return null;
                }} />
                <SecureRoute path="/">
                    <ToastContainer 
                        position="top-center"
                        closeOnClick={false}
                        draggable
                        pauseOnHover
                    />
                    <div className="grid grid-cols-sidebar grid-rows-1 h-full w-full fixed">
                        <div id="sidebar" className="">
                            <SidePanel />
                        </div>
                        <div id="main" className="col-span-1 col-start-2">
                            <Main />
                        </div>
                        <div id="action" className="col-span-1 col-start-3">
                            <ActionBar />
                        </div>
                    </div>
                </SecureRoute>
            </Switch>
        </>
    )
}

export default App