import React from 'react'

type DiagramType = {
    diagramLocation: string,
    alt: string
}

const Diagram: React.FC<DiagramType> = ({ diagramLocation, alt }) => {
    return (
        <img
            src={diagramLocation}
            className="h-full m-auto"
            style={{ minHeight: "400px" }}
            alt={`Diagram for ${alt}`}
        />
    )
}

export default Diagram