import React, { useState, useRef } from "react"
import { Link } from "react-router-dom"
import { usePopper } from 'react-popper'

import WorkspaceSelector from 'views/SidePanel/components/WorkspaceSelector'
import UserMenu from 'views/SidePanel/components/UserMenu'
import { menuList } from 'views/SidePanel/menuList'


const VerticalSidebar: React.FC = () => {

    const buttonRef = useRef(null);
    const popperRef = useRef(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
    const [isShow, setIsShow] = useState(false)

    const [expanded, setExpanded] = useState(true)

    const { styles, attributes } = usePopper(buttonRef.current, popperRef.current, {
        modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
        placement: "top-start",
    })

    const menuItems = menuList.map((item, index) => <item.type key={index} {...item.props} />)

    return (
        <div className={`bg-gray-100 h-full text-black flex flex-col border-r-2 border-gray-200  transform top-0 left-0 overflow-auto ease-in-out duration-300 z-30 origin-left ${expanded ? "translate-x-0" : 'scale-x-50'}`}>
            <div className="h-14 flex-shrink-0 border-gray-200 border-b-2">
                <Link to="/" className="float-left ml-2">
                    <h1 className="text-3xl logo hover:text-gold" onClick={() => setExpanded((prev) => !prev)}>Atria</h1>
                </Link>
            </div>
            { menuItems}
            <div className="flex-auto"></div>
            <WorkspaceSelector buttonRef={buttonRef} setVisible={setIsShow} />
            {isShow &&
                <div ref={popperRef} style={styles.popper} {...attributes.popper} >
                    <UserMenu />
                    <div ref={setArrowElement} style={styles.arrow} />
                </div>
            }
        </div>
    )
}
export default VerticalSidebar