import React, { useState, useEffect } from "react"
import { useParams, Link, useLocation } from "react-router-dom"
import ReactHtmlParser from 'react-html-parser'
import Card from "../../components/Card"
import useFetch from "../../services/useFetch"
import { toast } from "react-toastify"

type FullWidthLinkType = {
    text: string,
    link: string
}

const FullWidthLink: React.FC<FullWidthLinkType> = ({ text, link }) => {
    return (
        <Link to={link} >
            <button className=" w-full my-4 border border-blue-900 hover:bg-blue-900 hover:text-white shadow-sm cursor-pointer rounded-sm">{text}</button>
        </Link>
    )
}

type ArticleSectionBodyType = {
    location: any
}

type ArticleSectionBodyParamType = {
    articleId: string,
    sectionId: string
}

type ArticleSectionResponseType = {
    name: string,
    body: string,
    id: string
}

type ArticleSectionFetchResponseType = {
    status: string,
    data: ArticleSectionResponseType | null
}

const ArticleSectionBody: React.FC<ArticleSectionBodyType> = ({ location }) => {

    let { articleId, sectionId } = useParams<ArticleSectionBodyParamType>()

    const [articleContent, setArticleContent] = useState<any>(<></>)
    const [articleTitle, setArticleTitle] = useState("")
    const [hasLoaded, setHasLoaded] = useState(false)

    const { status, data }: ArticleSectionFetchResponseType = useFetch(`/article/${articleId}/${sectionId}?full_section=true&any_length=true`)
    useEffect(() => {
        if (status === 'error') {
            setArticleTitle("Error")
            setArticleContent(<p>An error has occured. Please try again in a bit.</p>)
            toast.error("Could not load the article. Please try again in a bit.")
            console.error(data)
            setHasLoaded(true)
        } else if (status === 'fetched' && data != null) {
            const articleData = data as ArticleSectionResponseType
            setArticleContent(ReactHtmlParser(articleData.body))
            setArticleTitle(articleData.name)
            setHasLoaded(true)
        }
    }, [status, location, data])

    return (
        <div className="article-content">
            <div className={hasLoaded ? "" : "animate-pulse flex bg-gray-400 w-1/3 h-8"}>
                {hasLoaded ?
                    <h1 className="text-4xl font-bold"> {articleTitle} </h1> : ""
                }
            </div>
            <FullWidthLink text="Read full article..." link={`/view/article/${articleId}`} />
            <div className={` mt-4 ${hasLoaded ? "" : "animate-pulse flex bg-gray-400 w-2/3 h-48"}`}>
                {articleContent}
            </div>
            <FullWidthLink text="Read full article..." link={`/view/article/${articleId}`} />
        </div>
    )
}

const ArticleSection = () => {
    let location = useLocation()

    return (
        <Card
            customStyle="px-4 lg:16 xl:px-32"
            key={location.pathname}
            title="Article Section"
            body={<ArticleSectionBody location={location} />}
        />
    )
}

export default ArticleSection