import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Split from 'react-split'


import Card from 'components/Card'
import { example } from 'Process/components/process_example'
import Documentation from 'Process/components/Documentation'
import Diagram from 'Process/components/Diagram'
import useFetch from 'services/useFetch'
import { NoMatchBody } from 'views/NoMatch'
import { toast } from "react-toastify"


const Panel = (props) => {
    const { children, classStyle } = props

    return (
        <div className={"h-full " + classStyle} >
            {children}
        </div>
    )
}

const ProcessActivityBody = (props) => {

    const data_diagram = example
    const { processId, activityId } = props
    const [activityData, setActivityData] = useState({})
    const [details, setDetails] = useState([])
    const { status, data } = useFetch(`/process/${processId}/${activityId}`)
    useEffect(() => {
        if (status === 'error') {
            toast.error("Something went wrong retrieving that content. Please try again later")
            console.error(status.data)
        } else if (status === 'fetched') {
            const { details, ...fetchedActivityData } = data.data
            setDetails(details)
            setActivityData(fetchedActivityData)
        }
    }, [activityId, processId, status, data])

    return (
        <>
            {status === "error" ? <NoMatchBody /> :
                <Split
                    sizes={[40, 60]}
                    minSize={100}
                    gutterSize={3}
                    direction="vertical"
                    cursor="n-resize"
                    className="flex flex-col h-full"
                >
                    <Panel classStyle="overflow-y-auto">
                        <Link to={`/view/process/${processId}`}>
                        <h1 className="mt-0 text-xl font-semibold hover:underline">{activityData.title}</h1>
                        </Link>
                        <Diagram diagramLocation={data_diagram.diagramLocation} alt={activityData.title} />
                    </Panel>
                    <Panel>
                        <Documentation title={activityData.name} documentation={activityData.text} details={details} />
                    </Panel>
                </Split>
            }
        </>
    )
}

const ProcessActivity = () => {
    let location = useLocation()
    let { processId, activityId } = useParams()
    return (
        <Card
            title="Process Activity"
            body={<ProcessActivityBody processId={processId} activityId={activityId} />}
            key={location.pathname}
            customStyle="h-full"
        />
    )
}

export default ProcessActivity