import React from 'react'
import { faComments, faBell, faPaperclip } from '@fortawesome/free-solid-svg-icons'

import MenuTab from 'views/ActionBar/components/MenuTab'

type MinimisedActionBarType = {
    toggle: (toggle: boolean) => void,
    active: boolean,
    setActiveTab: (tab: string) => void
}

const MinimisedActionBar: React.FC<MinimisedActionBarType> = ({ toggle, active, setActiveTab }) => {

    return (
        <div className={`h-full flex flex-col bg-gray-100 border-l-2 border-gray-200 pt-2 ${active && "hidden"}`}>
            <MenuTab
                icon={faComments}
                color="text-red-400"
                onClick={() => {
                    toggle(true)
                    setActiveTab("chat-assistant")
                }}
            />
            <MenuTab
                icon={faPaperclip}
                color="text-blue-400"
                onClick={() => {
                    toggle(true)
                    setActiveTab("linker")
                }}
            />
            <div className="flex-auto" ></div>
            <MenuTab
                icon={faBell}
                color="text-yellow-400"
            />
        </div>
    )
}

export default MinimisedActionBar