import React, { useContext } from 'react'

import { StorageContext } from 'services/StorageContext'
import ExpandedActionBar from 'views/ActionBar/components/ExpandedActionBar'
import MinimisedActionBar from 'views/ActionBar/components/MinimisedActionBar'

const ActionBar = () => {

    const { storage, updateStorage } = useContext(StorageContext)!

    const isExpanded = storage["showActionbar"] || false
    const setIsExpanded = (expandedToggle: boolean) => {
        updateStorage("showActionbar", expandedToggle)
    }

    const activeTab = storage["actionbarActiveTab"] || "chat-assistant"
    const setActiveTab = (newActiveTab: string) => {
        updateStorage("actionbarActiveTab", newActiveTab)
    }

    return (
        <>
            <ExpandedActionBar active={isExpanded} toggle={setIsExpanded} activeTab={activeTab} setActiveTab={setActiveTab} />
            <MinimisedActionBar active={isExpanded} toggle={setIsExpanded} setActiveTab={setActiveTab} />
        </>
    )

}

export default ActionBar