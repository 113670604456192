import React from 'react'
import { toast } from "react-toastify"

import Logout from "Security/Logout"
import MenuItem from 'views/SidePanel/components/MenuItem'

const UserMenu = () => {
    return (
        <div className="flex flex-col bg-white shadow rounded text-black py-1">
            <a href={`mailto:oliver.fritz@covarius.com?subject=[Atria] Page Layout Issue`}>
                <MenuItem iconColor="#000" children="Report issue with page" />
            </a>
            <MenuItem style="cursor-pointer" children={<Logout />} />
            <button onClick={() => toast.error("That was easy")}>This is the toast button</button>
        </div>
    )
}

export default UserMenu