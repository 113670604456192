import React from 'react'
import { faComments, faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons'

import MenuTab from 'views/ActionBar/components/MenuTab'
import Webchat from 'components/Webchat'


type ExpandedActionBarType = {
    toggle: (toggle: boolean) => void, 
    active: boolean, 
    activeTab: string,  // TODO: Change to Enum
    setActiveTab: (tab: string) => void
}

const ExpandedActionBar: React.FC<ExpandedActionBarType> = (props) => {

    const { toggle, active, activeTab, setActiveTab } = props

    return (
        <div
            className={`grid grid-cols-1 grid-rows-actionbar h-full border-l-2 border-gray-200 ${active || "hidden"}`}
            style={{ width: "500px" }}
        >
            <div className="row-span-1 col-span-1 h-14 bg-gray-100 flex flex-row border-b-2 border-gray-200">
                <MenuTab
                    icon={faComments}
                    color="text-red-400"
                    text="Chat Assistant"
                    active={activeTab === "chat-assistant"}
                    onClick={() => { setActiveTab("chat-assistant") }}
                />
                <MenuTab
                    icon={faPaperclip}
                    color="text-blue-400"
                    text="Linker"
                    active={activeTab === "linker"}
                    onClick={() => { setActiveTab("linker") }}
                />
                <div className="flex-auto"></div>
                <MenuTab
                    icon={faTimes}
                    color="text-gray-500"
                    onClick={() => { toggle(false) }}
                />
            </div>
            <div className="row-span-1 col-span-1 bg-gray-200 h-full overflow-y-auto">
                <div className={`chatbar h-full ${activeTab === "chat-assistant" || "hidden"}`} >
                    <Webchat />
                </div>
                {activeTab === "linker" && <span>Linked Placeholder</span>}
            </div>
        </div>
    )
}

export default ExpandedActionBar