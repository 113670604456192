import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import ReactHtmlParser from 'react-html-parser';
import Card from "../../components/Card"
import useFetch from "../../services/useFetch"
import { toast } from "react-toastify";

type ArticleBodyParamType = {
    articleId: string
}

type ArticleResponseType = {
    name: string,
    body: string,
    id: string
}

type ArticleFetchResponseType = {
    status: string,
    data: ArticleResponseType | null
}

const ArtileBody = () => {
    let { articleId } = useParams<ArticleBodyParamType>()

    const [articleContent, setArticleContent] = useState<any>(<></>)
    const [articleTitle, setArticleTitle] = useState("")
    const [hasLoaded, setHasLoaded] = useState(false)

    const { status, data }: ArticleFetchResponseType = useFetch(`/article/${articleId}`)
    useEffect(() => {
        if (status === 'error') {
            setArticleTitle("Error")
            setArticleContent(<p>An error has occured. Please try again in a bit.</p>)
            toast.error("Could not load the article. Please try again in a bit.")
            console.error(data)
            setHasLoaded(true)
        } else if (status === 'fetched' && data != null ) {
            const articleData = data as ArticleResponseType
            // ReactHtmlParser(articleData.body)
            setArticleContent(ReactHtmlParser(articleData.body))
            setArticleTitle(articleData.name)
            setHasLoaded(true)
        }
    }, [status, data])

    return (
        <div className="article-content max-w-6xl">
            <div className={hasLoaded ? "" : "animate-pulse flex bg-gray-400 w-1/3 h-12"}>
                {hasLoaded ?
                    <h1 className="text-4xl font-bold"> {articleTitle} </h1> : ""
                }
            </div>
            <div className={`mt-4 ${hasLoaded ? "" : "animate-pulse flex bg-gray-400 w-2/3 h-48"}`}>
                {articleContent}
            </div>
        </div>
    )
}

const Article = () => {
    return (
        <>
            <Card
                customStyle="px-4 lg:16 xl:px-32"
                title="Article"
                body={<ArtileBody />}
            />
        </>
    )
}

export default Article