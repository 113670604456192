import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Webchat from 'components/Webchat'
import HomePage from 'views/HomePage'
import NoMatch from 'views/NoMatch'
import Article from 'Article/Article'
import ArticleSection from 'Article/ArticleSection'
import ProcessActivity from 'Process/ProcessActivity'
import Process from 'Process/Process'

const Main = () => {
    return (
        <Switch>
            <Route exact path="/">
                <HomePage />
            </Route>
            <Route path="/view/article/:articleId/:sectionId">
                <ArticleSection />
            </Route>
            <Route path="/view/article/:articleId">
                <Article />
            </Route>
            <Route path="/view/process/:processId/:activityId">
                <ProcessActivity />
            </Route>
            <Route path="/view/process/:processId">
                <Process />
            </Route>
            <Route path="/chat">
                <Webchat />
            </Route>
            <Route path="*">
                <NoMatch />
            </Route>
        </Switch>
    )
}

export default Main