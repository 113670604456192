import React, { useEffect, useState } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { UserClaims } from '@okta/okta-auth-js'

import Card from 'components/Card'


const HomePage: React.FC = () => {

    const { oktaAuth } = useOktaAuth()
    const [userInfo, setUserInfo] = useState<UserClaims | null>(null)

    useEffect(() => {
        if (!oktaAuth.isAuthenticated) {
            setUserInfo(null)
        } else {
            oktaAuth.getUser().then(info => {
                setUserInfo(info)
            })
        }
    }, [oktaAuth])

    return (
        <Card
            title="Home page"
            body={
                <>
                    <h1 className="text-xl">Welcome back {userInfo && userInfo.given_name}!</h1>
                </>
            }
            contextMenu={[<p>This is a context Menu thing</p>]}
        />
    )
}

export default HomePage