import React from "react"
import { ReactComponent as Logo } from 'views/NoMatch/components/image_404.svg';

import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import Card from "components/Card"

type paramsType = {
    0: string
}

const NoMatchBody = () => {
    const params = useParams<paramsType>()
    return (
        <>
            <Logo className="not-found-image w-1/4 h-auto mx-auto my-12" />
            <h1 className="text-2xl font-bold">404 - Page not found</h1>
            <h3 className="text-lg font-semibold">Sorry, we cannot seem to find the page you are looking for.</h3>
            { (0 in Object.keys(params)) && <p>{params[0]}</p>}
            <Link to="/">
                <span className="py-1 px-2 rounded-sm bg-blue-nights hover:bg-gray-700 text-white">Back to homepage</span>
            </Link>
        </>
    )
}

const NoMatch = () => {
    return (
        <Card
            customStyle="text-center"
            title="404 - Page not found"
            body={<NoMatchBody />}
        />
    )
}

export { NoMatchBody }
export default NoMatch