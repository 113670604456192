import React, { MutableRefObject } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

type WorkspaceSelectorType = {
    buttonRef: MutableRefObject<null>,
    setVisible: (React.Dispatch<React.SetStateAction<boolean>>)
}

const WorkspaceSelector: React.FC<WorkspaceSelectorType> = ({ buttonRef, setVisible }) => {
    const workspace = "Covarius"

    const toggleMenu = () => {
        console.log("Toggle")
        setVisible((prevValue) => !prevValue)
    }

    return (
        <div
            className="flex flex-row m-2 p-2 border border-gray-200 rounded bg-white"
            ref={buttonRef}
        >
            <img
                src="https://media-exp1.licdn.com/dms/image/C560BAQGxuyiOmUMbVg/company-logo_200_200/0?e=2159024400&v=beta&t=M82_d5h9pLNSOu9jof6neCOei3dgNg2OyHiH8FIZzAM"
                className="rounded-sm h-10 cursor-pointer p2 my-auto"
                onClick={toggleMenu}
                alt="Avatar"
            />
            <div className="flex-auto pl-2">
                <h2 className="m-0 font-semibold">{workspace}</h2>
                <h3 className="m-0 text-sm">Workspace</h3>
            </div>
            <button className="my-auto" onClick={toggleMenu}>
                <FontAwesomeIcon icon={faChevronDown} className="text-gray-500" />
            </button>
        </div>
    )
}

export default WorkspaceSelector