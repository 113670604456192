import React from 'react'
import { useOktaAuth } from '@okta/okta-react'

const Logout = () => {
    const { oktaAuth } = useOktaAuth()

    const logout = async () => {
        oktaAuth.signOut({ postLogoutRedirectUri: window.location + 'logged_out' })
    }

    return (
        <a onClick={logout}>Sign out</a>
    );
}

export default Logout