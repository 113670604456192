import MenuItem from 'views/SidePanel/components/MenuItem'
import MenuDivider from 'views/SidePanel/components/MenuDivider'
import { faSitemap, faListOl, faTachometerAlt, faBook, faCodeBranch } from '@fortawesome/free-solid-svg-icons'

type menuListType = {
    type: React.FC<any>,
    props: { [key: string]: any }
}[]

export const menuList: menuListType = [
    {
        type: MenuDivider,
        props: {
            text: "Interfaces",
            noBorder: true
        }
    },
    {
        type: MenuItem,
        props: {
            icon: faSitemap,
            children: "Hierarchy"
        }
    },
    {
        type: MenuItem,
        props: {
            icon: faListOl,
            children: "Work Instructions"
        }
    },
    {
        type: MenuItem,
        props: {
            icon: faTachometerAlt,
            children: "Data Dashboard"
        }
    },
    {
        type: MenuDivider,
        props: {
            text: "Data Views"
        }
    },
    {
        type: MenuItem,
        props: {
            icon: faBook,
            iconColor: "blue-500",
            children: "Article View",
            link: "/view/article"
        }
    },
    {
        type: MenuItem,
        props: {
            icon: faCodeBranch,
            children: "Process View"
        }
    },

]