import React from "react"
import ReactDOM from "react-dom"
import './tailwind.output.css'
import App from "./App"
import { BrowserRouter as Router } from "react-router-dom"
import { Security } from '@okta/okta-react'
import {StorageContextProvider} from "services/StorageContext"
import { OktaAuth } from "@okta/okta-auth-js"

const oktaConfig = {
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: `${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
    redirectUri: `${window.location.protocol}//${window.location.host}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true
}

const oktaAuth = new OktaAuth(oktaConfig)

ReactDOM.render(
    <Router>
        <Security oktaAuth={oktaAuth}>
            <StorageContextProvider>
                <App />
            </StorageContextProvider>
        </Security>
    </Router>,
    document.getElementById("root")
)