import React from 'react'

export type MenuDividerType = {
    text: string,
    noBorder?: boolean
}

const MenuDivider: React.FC<MenuDividerType> = ({ text, noBorder }) => {
    return (
        <div className={`text-gray-500 ${noBorder || "border-t-2 border-gray-200"}`} >
            <h2 className="mt-0 mb-1 ml-2">{text}</h2>
        </div>
    )
}


export default MenuDivider