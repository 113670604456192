import React from 'react'

type ContextMenuType = {
    contextItems: JSX.Element[]
}

const ContextMenu: React.FC<ContextMenuType> = ({ contextItems }) => {
    const styledChildren = contextItems!.map((child) => <ContextMenuItem>{child}</ContextMenuItem>)
    return (
        <div className="bg-gray-100 rounded-md shadow w-64 max-w-full overflow-x-hidden overflow-y-auto max-h-full h-64 padding">
            {styledChildren}
        </div>
    )
}

const ContextMenuItem: React.FC = ({ children }) => {
    return (
        <span className="w-full inline-block my-2 mx-2">
            {children}
        </span>
    )
}

export default ContextMenu