import { useOktaAuth } from "@okta/okta-react"
import { useEffect, useState, useContext } from "react"
import { StorageContext } from "./StorageContext"

const useFetch = (url: string, forced: boolean = false) => {
    const BASE_URL = process.env.REACT_APP_BACKEND_URL

    const { storage, updateStorage } = useContext(StorageContext)!
    const cache = storage["cache"] || {}

    const updateCache = (newCache: any) => {
        updateStorage("cache", newCache)
    }

    const [status, setStatus] = useState('idle')
    const [data, setData] = useState(null)
    const { authState } = useOktaAuth()

    const accessToken = authState.accessToken

    useEffect(() => {
        if (!url) return
        const fetchData = async () => {
            setStatus('fetching')
            if (!forced && cache[url] && (cache[url].timestamp * 24 * 60 * 60) > Date.now()) {
                const data = cache[url].data
                setData(data)
                setStatus('fetched')
            } else {
                try {
                    const resp = await fetch(`${BASE_URL}${url}`, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }
                    })
                    const data = await resp.json()
                    console.log(resp)
                    if (resp.status !== 200) {
                        setData(data)
                        setStatus('error')
                    } else {
                        const cache_insert = { data: data, timestamp: Date.now() }
                        updateCache({ [url]: cache_insert, ...cache })
                        setData(data)
                        setStatus('fetched')
                    }
                } catch (err) {
                    console.error(err)
                    setData(err)
                    setStatus('error')
                }
            }
        }

        fetchData()
    }, [url, BASE_URL, accessToken])

    return { status, data }
}

export default useFetch

export const CHATCORE_URL = process.env.REACT_APP_CHATCORE_URL