import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import ProcessDetail from 'Process/components/ProcessDetail'
import Section from 'components/Section'

type DetailsListType = {
    id: string,
    type: string,
    name: string
}

type DocumentationType = {
    documentation: string,
    title: string,
    details: DetailsListType[]
}

const Documentation: React.FC<DocumentationType> = ({ documentation, title, details }) => {
    details = details || []
    const detail_list = details.map((dict) => <ProcessDetail key={dict.id} type={dict.type} name={dict.name} />)
    return (
        <div className="h-full w-full overflow-y-auto">
            <div className="mx-4 md:mx-16 lg:mx-32 ">
                <h1 className="font-bold text-xl">{title}</h1>
                <Section
                    sectionName="Details"
                    contents={detail_list}
                />
                <Section
                    sectionName="Documentation"
                    contents={ReactHtmlParser(documentation)}
                />
            </div>
        </div>
    )
}

export default Documentation