import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export type MenuItemType = {
    icon?: IconDefinition,
    iconColor?: string,
    active?: boolean,
    style?: string,
    link?: string
}

const MenuItem: React.FC<MenuItemType> = ({ icon, iconColor, active, children, style, link }) => {
    const [isHover, setIsHover] = useState(false)
    const hoverIconColor = iconColor ? iconColor : "gray-500"

    return (
        <Link to={link || "#"}>
            <div
                className={`px-2 mx-2 py-1 rounded-lg hover:bg-gray-50 ${active && "border-l-2 border-" + hoverIconColor} ${style}`}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                {icon &&
                    <FontAwesomeIcon
                        icon={icon}
                        className={`${isHover ? "text-" + hoverIconColor : "text-gray-500"} mr-2`}
                    />
                }
                {children}
            </div>
        </Link>
    )
}

export default MenuItem