import React, { useEffect, useState } from "react"
import { CHATCORE_URL } from "../services/useFetch"
import { Link } from "react-router-dom"
import { Widget, addResponseMessage, toggleWidget, renderCustomComponent } from 'react-chat-widget'
import 'react-chat-widget/lib/styles.css';

const sendMessageToBackend = async (message: string) => {
    const payload = {
        "sender": "Rasa",
        "message": message
    }
    try {
        const resp = await fetch(`${CHATCORE_URL}/webhooks/rest/webhook`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
        const data = await resp.json()
        return data
    } catch (err) {
        console.log(err)
    }
}

type MessageLinkType = {
    link: string
}

const MessageLink: React.FC<MessageLinkType> = ({ link }) => {
    return (
        <div className="rcw-response">
            <div className="rcw-message-text">
                <Link to={link} className="text-blue-900 hover:font-bold">
                    Link to page
                </Link>
            </div>
        </div>
    )
}

type SummaryLinkType = {
    header: string,
    body: string,
    link: string
}

const SummaryLink: React.FC<SummaryLinkType> = ({ header, body, link }) => {

    const [isHovered, setIsHovered] = useState(false)

    return (
        <div className="flex flex-col" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {/* <div className="flex flex-col"> */}
            <div className="rcw-response">
                <div className="rcw-message-text">
                    <h1 className="font-bold">{header}</h1>
                    <p className="pb-1">{body}</p>
                </div>
            </div>
            <div className={` mt-3 ${isHovered || "hidden"}`}>
                <Link to={link} className="py-1 px-2 rounded-lg border hover:border-seabrook bg-seabrook text-white hover:bg-white hover:text-black">
                    Open in view...
                </Link>
            </div>
            <div className={` mt-1 ${isHovered && "hidden"}`}>
                <span className="bg-seabrook h-2 w-24 block rounded-full">
                    <p className="invisible">Open in view...</p>
                </span>
            </div>
        </div>
    )
}

const Webchat = () => {

    useEffect(() => {
        toggleWidget()
    }, [])

    const handleNewUserMessage = async (newMessage: string) => {
        const responseMessage = await sendMessageToBackend(newMessage)
        for (var index in responseMessage) {
            delete responseMessage[index]["recipient_id"]
            const responseType = Object.keys(responseMessage[index])[0]
            switch (responseType) {
                case "text":
                    addResponseMessage(responseMessage[index]["text"])
                    break
                case "custom":
                    console.log(responseMessage[index])
                    const customMessage = responseMessage[index]["custom"]
                    switch (customMessage.type) {
                        case "link":
                            renderCustomComponent(MessageLink, { link: customMessage.data })
                            break

                        case "summary_link":
                            console.log(customMessage)
                            renderCustomComponent(SummaryLink, { ...customMessage.data })
                            break
                        default:
                            break
                    }
                    break
                default:
                    console.log(responseMessage[index])
                    break
            }
        }
    }

    return (
        <Widget
            handleNewUserMessage={handleNewUserMessage}
            fullScreenMode={true}
            showCloseButton={false}
        />
    )
}


export default Webchat